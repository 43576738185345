<template>
  <div>
    <div class="card">
      <div class="card-body">
        <form action="">
          <div class="form-row">
            <div class="col-md-4">
              <select v-model="search_data.status_type"  id="status_type" name="status_type" class="form-control form-control-sm">
                <option selected :value="''">Select a status</option>
                <option value="PENDING">Pending</option>
                <option value="INPROGRESS">Inprogress</option>
                <option value="COMPLETED">Completed</option>
              </select>
            </div>
            <div class="col-md-4">
              <input type="text" v-model="search_data.email_address" v-validate="'email'" name="email_address" id="email_address" class="form-control form-control-sm" placeholder="Enter email address" />
            </div>
            <div class="col-md-4">
              <a-range-picker @change="onDateRangeChange" />
            </div>
          </div>
          <div class="form-row mt-2">
            <div class="col-md-4">
              <a-button class="btn btn-success btn-block" :loading="btnLoading" @click.prevent="search()"><i class="fa fa-search mr-1" aria-hidden="true"></i>Search</a-button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-sm table-bordered text-center" id="hub_admin_list">
            <thead>
            <tr>
              <th>#</th>
              <th>Issue No.</th>
              <th>Accessory Name</th>
              <th>Issue Created</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(hub_accessory_issue, index) in hub_accessory_issues.data" :key="index">
              <td>{{index + 1 }}</td>
              <td>{{ hub_accessory_issue.issue_no }}</td>
              <td>{{ hub_accessory_issue.admin_scenario_accessory.name }}</td>
              <td>{{ customDate(hub_accessory_issue.created_at) }}</td>
              <td>
                <a href="javascript: void(0);" v-b-modal.admin-scenario-issue-status-modal @click="$bvModal.show('admin-scenario-issue-status-modal'), status(hub_accessory_issue.max_admin_scenario_issue_status, hub_accessory_issue)" class="btn btn-sm btn-primary">
                  <i class="fe fe-eye"/>
                </a>
              </td>
              <td>
                <router-link :to="{ name: 'adminScenarioHubIssueDetails', params: { admin_scenario_issue_id: hub_accessory_issue.id } }" class="btn btn-sm btn-success mr-2" tag="a">
                  <i class="fe fe-eye"/> Details
                </router-link>
                <a v-if="hub_accessory_issue.admin_scenario_issue_status.includes('ISSUE_PENDING') && !hub_accessory_issue.admin_scenario_issue_status.includes('ISSUE_IN_PROGRESS_BY_SOLVER') && !hub_accessory_issue.admin_scenario_issue_status.includes('ISSUE_SOLVED_BY_SOLVER') && !hub_accessory_issue.admin_scenario_issue_status.includes('ISSUE_CONFIRMED_BY_ISSUER')" class="btn btn-sm btn-danger mr-2" href="javascript: void(0);" @click.prevent="onDelete(hub_accessory_issue.id)"><i class="fa fa-trash mr-1"></i>Delete</a>
              </td>
            </tr>
            </tbody>
          </table>
          <a-skeleton active :loading="loading"></a-skeleton>
          <h5 class="text-center text-secondary" v-if="hub_accessory_issues.length === 0 && !flag">
            <Empty :image="simpleImage"></Empty>
          </h5>
          <hr>
          <div class="float-right">
            <pagination class="mt-2" :data="hub_accessory_issues" :limit="2" @pagination-change-page="getResults"></pagination>
          </div>
        </div>
      </div>
    </div>
    <status :current="current" :admin_scenario_issue="admin_scenario_issue"></status>
  </div>
</template>

<script>
import moment from 'moment'
import { Empty } from 'ant-design-vue'
import apiClient from '@/services/axios'
import Status from '@/views/adminScenario/issue/partial/status'

export default {
  name: 'List',
  components: { Empty, Status },
  data() {
    return {
      hub_accessory_issues: {},
      admin_scenario_issue: {},
      current: 0,
      loading: false,
      loader: false,
      flag: false,
      btnLoading: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      search_data: {
        status_type: '',
        email_address: '',
        date_range: '',
      },
    }
  },
  mounted() { },
  methods: {
    search() {
      this.loading = true
      this.btnLoading = true
      this.flag = true
      apiClient.post('api/admin-scenario/hub/issue/search', this.search_data)
        .then(response => {
          this.loading = false
          this.btnLoading = false
          this.flag = true
          this.hub_accessory_issues = response.data.hub_accessory_issues
        })
        .catch(error => {
          console.log(error)
        })
    },
    getResults(page = 1) {
      apiClient.post('api/admin-scenario/hub/issue/search?page=' + page, this.search_data)
        .then(response => {
          this.hub_accessory_issues = response.data.hub_accessory_issues
        })
    },
    onDateRangeChange(date, dateString) {
      this.search_data.date_range = dateString
    },
    customDate(date) {
      return moment(date).format('LL')
    },
    onDelete(issueId) {
      if (confirm('Do you really want to delete?')) {
        apiClient.delete('api/admin-scenario/issue/delete/' + issueId).then(response => {
          if (!response.error) {
            if (response.data.error === true) {
              this.$notification.error({
                message: response.data.message,
              })
            } else {
              this.$notification.success({
                message: response.data.message,
              })
            }
            this.search()
          } else {
            this.$notification.error({
              message: 'Deleted Failed',
            })
          }
        }).catch(error => {
          console.log(error)
        })
      }
    },
    status(currentAdminScenarioIssueStatus, adminScenarioIssue) {
      this.current = 0
      this.admin_scenario_issue = ''
      this.current = currentAdminScenarioIssueStatus
      this.admin_scenario_issue = adminScenarioIssue
    },
  },
}
</script>

<style scoped>

</style>
